<template>
  <div class="page-content">
    <!-- Copyright Section -->
    <section id="copyright-section" class="page-section bg-white text-white mb-0">
      <div class="container align-items-center">

        <!-- Copyright Heading -->
        <div class="text-center mt-3 mb-3">
          <h1 class="page-section-heading text-uppercase text-secondary pb-lg-3">Copyright</h1>
        </div>

        <!-- Copyright Section Content -->
        <div class="row">
          <div class="col-lg-8 mx-auto">
            <p class="text-primary">De Brabant-Collectie heeft de auteursrechten van de getoonde items of anderzijds
              geprobeerd de rechthebbenden te achterhalen. Voor zover personen menen auteursrechtelijk aanspraken te
              hebben,
              kunnen zij contact opnemen via <a href="mailto:brabant@tilburguniversity.edu" class="text-secondary">brabantcollectie@uvt.nl</a>.
            </p>
            <p class="text-primary">Nadere informatie met betrekking tot hergebruik van getoonde items is te vinden op
              de pagina
              <a href="https://www.tilburguniversity.edu/nl/campus/brabantcollectie/faciliteiten/reproducties-bestellen/reproduceerregels"
                 target="_blank"
                 class="text-secondary">Reproduceerregels Brabant-Collectie
              </a>.
            </p>
          </div>
        </div>
      </div>
    </section>

    <!-- Copyright close button -->
    <CloseButton/>

  </div>
</template>

<script>
import CloseButton from '../components/CloseButton'

export default {
  name: 'Copyright',
  components: {
    CloseButton
  },
  methods: {
    windowClose() {
      window.open('', '_parent', '')
      window.close()
    }
  }
}
</script>